import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './store/utils.js'
import axios from './axios'
import './assets/css/index.css'
Vue.prototype.$baseUrl = store.baseUrl
Vue.prototype.$version = store.version
Vue.prototype.$utils = utils
Vue.prototype.$axios = axios;
//引入 ElementUi
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
