<template>
    <div class="submit-page" id="app">
        <div class="submit-page-card">
            <div class="submit-page-card-left">
                <img :src="require('/src/assets/image/submit/submit-bg-image.png')" class="submit-page-card-left-img" alt="">
            </div>
            <div class="submit-page-card-right">
                <div>
                    <h1>欢迎登陆</h1>
                    <el-form>
                        <el-input placeholder="请输入账号" v-model="username">
                            <img slot="prefix" :src="require('/src/assets/image/icon/username.svg')" />
                        </el-input>
                        <el-input placeholder="请输入密码" v-model="password" type="password">
                            <img slot="prefix" :src="require('/src/assets/image/icon/password.svg')" />
                        </el-input>
                        <el-checkbox v-model="submitChecked">
							<span style="font-size: 12px;color: #000;">记住密码</span>
						</el-checkbox>
						<el-button style="width: 100%;background-color: #3378FF;margin: 20px 0;" @click="goHomePage" type="primary">登 录</el-button>
<!--                        <a style="text-align: center;">
                            <el-button type="text" style="color: #666666;font-size: 12px;">忘记密码？</el-button>
                        </a> -->
                    </el-form>
                </div>
				<div>{{version}}</div>
            </div>
        </div>
    </div>
</template>
  
<script src="https://unpkg.com/axios/dist/axios.min.js"></script>
<script>
export default({
    name: 'Login',
    data() {
        return {
            username: '',
            submitChecked: false,
            password: '',
			companyConfigStr:'',
			roleDto:[],
			isLogin: true,
			version: this.$version
        }
    },
	created(){
		this.initAccount();
	},
    methods:{
		initAccount() {//初始化账号信息
			this.username = localStorage.getItem("username");
			this.password = localStorage.getItem("password");
			if (this.username && this.password) {
				this.submitChecked = true;
			}
		},
        goHomePage(){
			if (this.isLogin) {
				this.isLogin = false;
				this.$axios({
				  url: this.$baseUrl, //1、请求地址
				  method: 'post',     //2、请求方法
				  contentType:"application/json",                                    
				  params: {
					  'command_id':'login',
					  'userName' : this.username,
					  'pwd' : this.password,
				  },                 //3、get请求参数
				})
				.then(resp => {// 2、回调函数
				    console.log("resp====>>>",resp)
					var flag = resp.data.success;
					localStorage.setItem("username",this.username);
					if (flag) {
						this.$utils.fullScreen();//自动全屏
						localStorage.setItem("token",resp.data.msg);
						localStorage.setItem("USER_INFO",resp.data.data.companyName);
						localStorage.setItem("name",resp.data.data.name);
						if (this.submitChecked) {
							localStorage.setItem("password",this.password);
						}
						this.companyConfigStr = resp.data.data.companyConfigStr;
						this.roleDto = resp.data.data.roleDto;
						var roleTrue = "";
						for (var i = 0; i < this.roleDto.length; i++) {
							const role = this.roleDto[i];
							var postCode = role.postCode;
							if (postCode == "21") {
								roleTrue = postCode;
								break;
							}
						}
						if ("21" == roleTrue) {
							this.$router.push({ name: 'HomePage'})
						} else {
							this.$alert('没有高叉权限,无法登录!', '提示', {
								confirmButtonText: '确定',
							});
							this.isLogin = true;
						}
				  } else {
					this.isLogin = true;
					this.$alert(resp.data.msg, '提示', {
						confirmButtonText: '确定',
					});
				  }
				})
				.catch(err => {// 3、捕获异常
				    this.isLogin = true;
					this.$alert("网络异常!", '提示', {
						confirmButtonText: '确定',
					});
				    console.log("catch===>>>",err);
				});
			}

        }
    }
})
</script>
