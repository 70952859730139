<template>
    <div>
        <div class="gjwl-filter-page">
            <div class="gjwl-filter-page-header">
                <div class="gjwl-filter-page-header-left">
                    <div class="gjwl-filter-page-header-avator">
                        <img :src="require('/src/assets/image/avator.png')" />
                    </div>
                    <div>
                        <div class="gjwl-filter-page-header-username">
                            {{name}}
                        </div>
                        <div class="gjwl-filter-page-header-company">
                            {{companyName}}
                        </div>
                    </div>
                </div>
                <el-button round @click="exitToLogin">退出<img style="margin-left: 6px;" :src="require('/src/assets/image/icon/exitSubmit.svg')" /></el-button>
            </div>
            <div class="gjwl-filter-page-body">
                <div class="gjwl-filter-page-body-item" @click="gohouseWork">
                    <h1>高叉上下架</h1>
                    <div class="img-box">
                        <img class="img-style1" :src="require('/src/assets/image/filter-item1-img.png')" alt="">
                    </div>
                    <div class="text"></div>
                </div>
                <div class="gjwl-filter-page-body-item" @click="gohouseTransfer">
                    <h1>移库</h1>
                    <div class="img-box">
                        <img class="img-style2" :src="require('/src/assets/image/filter-item2-img.png')" alt="">
                    </div>
                    <div class="text"></div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default ({
    name: 'HomePage',
    data() {
        return {
		 name: '',
		 companyName:''
        }
    },
	mounted(option) {
	    // 在此处执行页面加载完成后的操作
		// console.log(this.$route.params);
	   this.name=localStorage.getItem("name");
	   console.log(localStorage.getItem("USER_INFO"));
	   this.companyName=localStorage.getItem("USER_INFO");
	  },
	  created() {
	      // 在此处执行页面创建完成后的操作
	    },
    methods: {
        gohouseWork() {
            this.$router.push('/housework')
        },
        gohouseTransfer() {
            this.$router.push('/transfer')
        },
        exitToLogin() {
			this.$utils.exitFullscreen()
            this.$router.push('/')
        },
    },
})
</script>

